import React from 'react';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import Screen from '../../components/screen';
import styles from './view.scss';

function View(props) {
  const { t } = props;

  return (
    <Screen>
      <div className={styles.space} />
      <Typography className={styles.text} color="textPrimary" variant="body2" align="center">
        {t('landing.info')}
      </Typography>
      <div className={styles.space} />
    </Screen>
  );
}
export default withTranslation()(View);
