import React from 'react';
import ReactDOM from 'react-dom';
import App from '../../app';
import View from './view';

ReactDOM.render(
  <App>
    <View />
  </App>,
  document.getElementById('root')
);
