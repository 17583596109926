// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2QuLUxQRyqG2I9cAy7qBao{width:100%;height:100%;display:flex;flex-direction:column;align-items:center;justify-content:center}.m4KwdxqZ7VYu3-JRPB1mR{width:500px;height:600px;background-color:#fff;border-radius:15px;display:flex;flex-direction:column;align-items:center;box-sizing:border-box}._2iA4LcTf4Wepq9hp1qfEZ5{flex:1}._26nLW7-vv0KOFXtwXxrt6q{width:140px;margin-top:30px}", "",{"version":3,"sources":["webpack://./src/components/screen/screen.scss"],"names":[],"mappings":"AAAA,yBACE,UAAA,CACA,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CAGF,uBACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAEA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,qBAAA,CAGF,yBACE,MAAA,CAEF,yBACE,WAAA,CACA,eAAA","sourcesContent":[".screen {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.container {\n  width: 500px;\n  height: 600px;\n  background-color: #fff;\n  border-radius: 15px;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  box-sizing: border-box;\n}\n\n.space {\n  flex: 1;\n}\n.icon {\n  width: 140px;\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screen": "_2QuLUxQRyqG2I9cAy7qBao",
	"container": "m4KwdxqZ7VYu3-JRPB1mR",
	"space": "_2iA4LcTf4Wepq9hp1qfEZ5",
	"icon": "_26nLW7-vv0KOFXtwXxrt6q"
};
export default ___CSS_LOADER_EXPORT___;
