import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import LogoIcon from '../../resources/images/icon.png';
import styles from './screen.scss';

function Screen(props) {
  const { t, children } = props;

  return (
    <div className={styles.screen}>
      <Paper elevation={3}>
        <div className={styles.container}>
          <img className={styles.icon} alt="" src={LogoIcon} />
          <Typography className={styles.title} color="textPrimary" variant="h6">
            {t('app_name')}
          </Typography>
          {children}
        </div>
      </Paper>
    </div>
  );
}
export default withTranslation()(Screen);
