// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1MlsVKuJxjuDiY66aqH1aM{flex:1}._1eX5sa2Er6ZTIZnOSn7_5f._1eX5sa2Er6ZTIZnOSn7_5f{margin-bottom:30px;margin-left:20px;margin-right:20px}", "",{"version":3,"sources":["webpack://./src/pages/landing/view.scss"],"names":[],"mappings":"AAAA,yBACE,MAAA,CAGF,iDACE,kBAAA,CACA,gBAAA,CACA,iBAAA","sourcesContent":[".space {\n  flex: 1;\n}\n\n.text.text {\n  margin-bottom: 30px;\n  margin-left: 20px;\n  margin-right: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"space": "_1MlsVKuJxjuDiY66aqH1aM",
	"text": "_1eX5sa2Er6ZTIZnOSn7_5f"
};
export default ___CSS_LOADER_EXPORT___;
